import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";

const Recruitment = () => (
  <StaticQuery
    query={graphql`
      query {
        recruitment1: file(relativePath: { eq: "recruitment-1.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        recruitment2: file(relativePath: { eq: "recruitment-2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        recruitment3: file(relativePath: { eq: "recruitment-3.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet>
          <title>Coolblue recruitmentsite | Charlotte de Hilster</title>
        </Helmet>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 45%;
            grid-column-gap: 24px;
            width: 100%;
            @media only screen and (max-width: 600px) {
              grid-template-columns: 100%;
            }
          `}
        >
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                grid-row: 2;
              }
            `}
          >
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.recruitment1.childImageSharp.fluid}
            />
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.recruitment2.childImageSharp.fluid}
            />
            <Img fluid={data.recruitment3.childImageSharp.fluid} />
          </div>
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                grid-row: 1;
              }
            `}
          >
            <h3
              css={css`
                font-family: "Lato", sans-serif;
                font-weight: bold;
                font-size: 32pt;
                @media only screen and (max-width: 600px) {
                  font-size: 24pt;
                }
              `}
            >
              Coolblue recruitmentsite
            </h3>
            <p
              style={{
                fontFamily: `Lato, sans-serif`,
                fontWeight: `lighter`,
                fontSize: `14pt`
              }}
            >
              In 2015 was Coolblue op zoek naar 1000 nieuwe medewerkers. In 2016
              was Coolblue op zoek naar 1500 nieuwe mederwerkers. Zoveel nieuwe
              medewerkers maar hoe vind je ze? Onder andere met een goede
              recruitmentsite. Dus het was de hoogste tijd om de toenmalige
              recruitmentsite onder handen te nemen.
              <br />
              <br />
              De nieuwe recruitmentsite moest naast de vacatures ook laten zien
              wie Coolblue is. Dat is onder andere gedaan met de
              vacaturedetailpagina die geschreven is alsof het een productpagina
              is. Maar ook door feitjes over Coolblue te laten zien,
              afbeeldingen met echte medewerkers en een sneak peak te geven van
              de werkplek waar je terecht komt.
              <br />
              <br />
              <a
                css={css`
                  color: #59bfa9;
                `}
                href="https://werkenbijcoolblue.nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                werkenbijcoolblue.nl
              </a>
            </p>
          </div>
        </div>
      </Layout>
    )}
  />
);

export default Recruitment;
